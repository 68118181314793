<template ref="adminUser">
  <div>
    <header-admin-pages
      title="Общественные палаты регионов по округам"
      :counter="$store.getters.getInfoAdvice.length"
      :button="{ title: 'Добавить', link: '/admin/info_advice/create?type=create' }"
    />
    <main>
      <table>
        <tr>
          <td><p class="text-content">Название палаты</p></td>
          <td><p class="text-content">Руководитель Аппарата</p></td>
          <td><p class="text-content">Email</p></td>
          <td></td>
        </tr>
        <tr v-for="(item, i) in $store.getters.getInfoAdvice" :key="`members${i}`">
          <td><p class="text-content">{{ item.name }}</p></td>
          <td><p class="text-content">{{ item.chairman }}</p></td>
          <td><p class="text-content">{{ item.email }}</p></td>
          <td>
            <drop-list @clickItem="clickItem" :idItem="item.id"/>
          </td>
        </tr>
      </table>
      <pagination/>
    </main>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import Pagination from '@/components/Pagination'
import DropList from '@/components/Admin/DropList'
export default {
  name: 'infoNko',
  data () {
    return {
      filers: {
        key: null,
        state: null
      },
      convocationList: []
    }
  },
  methods: {
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить законопроект')
        if (consent) {
          this.$store.dispatch('deleteInfoAdvice', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      } else {
        this.$store.dispatch('setDetailInfoAdvice', value.id)
      }
    },
    changeStatusDraft (e) {
      this.filers.state = e.target.value
    }
  },
  mounted () {
    this.$store.dispatch('setInfoAdvice')
    this.$store.dispatch('setStatusInfoNko')
  },
  components: {
    HeaderAdminPages,
    Pagination,
    DropList
  }
}
</script>

<style scoped lang="scss">
.filters{
  margin-top: 1.37rem;
  display: flex;
  justify-content: space-between;
  justify-items: baseline;
  flex-wrap: wrap;
  border-bottom: 1px solid #D5D6D9;
}
.input{
  width: 32%;
  margin-bottom: 1.25rem;
}
.wrapper-btn-filters {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.25rem;
}
.filters-reset {
  background: none;
  font-size: 0.75rem;
  color: #003E78;
  opacity: 0.6;
  margin-right: 1.37rem;
  cursor: pointer;
}
table{
  margin-top: 2rem;
  border-spacing: 0;
  border-collapse: collapse;

  tr{
    display: flex;
    justify-content: space-between;
    padding: 0;
    border-bottom: 1px solid #D5D6D9;
  }
}
tr {
  p {
    font-size: 0.812rem;
    line-height: 154%;
    color: #5E686C;
  }
}
tr {
  a {
    font-size: 0.812rem;
    line-height: 154%;
    color: #246CB7;
  }
}

.text-content{
  width: 15rem;
}
</style>
